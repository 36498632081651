'use client';

import * as React from 'react';
import type { CSSProperties, ReactNode } from 'react';
import { cn } from 'class-merge';
import Link from 'next/link';
import { useTheme } from '~context/theme';
import type { ButtonVariants, ButtonColors, SizeType } from '~constants/etc';
import getButtonStyles from '~libs/get-button-styles';

export interface ButtonProps {
  href?: string;
  target?: string;
  disabled?: boolean;
  loading?: boolean;
  variant?: ButtonVariants.FILL | ButtonVariants.GHOST;
  size?: SizeType;
  color?: ButtonColors | string;
  block?: boolean;
  icon?: ReactNode;
  className?: string;
  custom?: boolean;
  style?: CSSProperties;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: React.HTMLAttributes<HTMLButtonElement>['onClick'];
  children?: ReactNode;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      href,
      target,
      icon,
      loading,
      onClick,
      custom,
      className,
      children,
      block,
      ...rest
    },
    ref,
  ) => {
    const { button } = useTheme();
    const {
      buttonBase,
      buttonLoading,
      buttonLoadingAnimation,
      buttonSize,
      buttonVariant,
      buttonBlock,
    } = getButtonStyles({
      ...rest,
      block,
      ...button,
    });

    const classes = cn(
      buttonBase,
      buttonBlock,
      loading && buttonLoading,
      !custom && cn(buttonVariant, buttonSize),
      className,
    );

    const renderChildren = (): ReactNode => {
      if (loading) {
        if (children) {
          return <div className={buttonLoadingAnimation} />;
        }
        return null; //TODO add spin icon
      }
      return (
        <>
          {icon}
          {children}
        </>
      );
    };

    if (href) {
      return (
        <Link className={classes} href={href} role="button" target={target}>
          {renderChildren()}
        </Link>
      );
    }

    return (
      <button
        {...rest}
        className={classes}
        onClick={(e) => {
          if (loading) return;
          if (typeof onClick === 'function') {
            onClick(e);
          }
        }}
        ref={ref}
      >
        {renderChildren()}
      </button>
    );
  },
);

Button.displayName = 'Button';
